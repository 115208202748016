import { sendPageView } from "../../../../utils/SiteAnalytics";

const showTrialDiv = () => {
    let eles = document.getElementsByClassName("hero__trial__message");
    if(eles && eles.length > 0) {
        for(let ix in eles) {
            let ele = eles[ix];
            if(ele && ele.style) {
                ele.style.display = "block";
            }
        }
    }
}

export const processSupport = (isSupported, browserName, translations={}) => {
    if(!browserName) {
        browserName = " ";
    } 
    showTrialDiv();
    let jsBrowserName = browserName.split(",")[0];
    let jsBrowserStore = "";
    if(jsBrowserName === "Firefox") {
        jsBrowserStore = "Firefox Add-ons";
    } else if(jsBrowserName === "Chrome") {
        jsBrowserStore = "Chrome Web Store";
    } else if(jsBrowserName === "Microsoft Edge") {
        jsBrowserStore = "Microsoft Edge Addons";
    }
    var elems = document.getElementsByClassName("addtobrowserId");
    if(elems && elems.length > 0) {
        let transKey = "lpAddToWebSafety";
        for(var ix=0; ix < elems.length; ix++) {
            if(translations[transKey]) {
                elems[ix].innerHTML = translations[transKey].replace("{1}", jsBrowserName);
            }
        }
    }
    if(jsBrowserStore) {
        let transKey = "lpClickTo {1}";
        let elems = document.getElementsByClassName("compatibleBrowserWords");
        if(elems && elems.length > 0) {
            for(let ix=0; ix < elems.length; ix++) {
                if(translations[transKey]) {
                    elems[ix].innerHTML = translations[transKey].replace("{1}", jsBrowserName);
                }
            }
        }
    }
    if(isSupported) {
        let analyticsBit = window.localStorage.getItem('analytics_bit');
        if(!analyticsBit || analyticsBit == 3) {
            sendPageView("/lp/qualified");
            window.localStorage.setItem('analytics_bit', 1);
        }
        //say compatible with your browser
        document.getElementById("compatibleBrowserId").style.display = "flex";
        if(browserName === "Chrome") {
            document.getElementById("chromeLogoId").style.display = "block";
        } else if(browserName === "Firefox") {
            document.getElementById("firefoxLogoId").style.display = "block";
        } else if(browserName === "Microsoft Edge") {
            document.getElementById("edgeChromiumLogoId").style.display = "block";
        }
    } else {	
        //add body class as not supported
        document.body.classList.add("blurNotSupported");
        //set the browser Name
        let transKey = "lpNotSupported {1}";
        var notSupportedId = document.getElementById("notSupportedId");
        if(notSupportedId) {
            if(translations[notSupportedId]) {
                elems[ix].innerHTML = translations[transKey].replace("{1}", jsBrowserName);
            }
        }
        //disable the download button
        var downloadId = document.getElementById("downloadId");
        if(downloadId) {
            downloadId.disabled = true;
        }
        var footerDownloadId = document.getElementById("footerDownloadId");
        if(footerDownloadId) {
            footerDownloadId.disabled = true;
        }
        var headerDownloadId = document.getElementById("headerDownloadId");
        if(headerDownloadId) {
            headerDownloadId.disabled = true;
        }
        //display the form
        var subscFormId = document.getElementById("subscFormId");
        if(subscFormId) {
            subscFormId.style.display = "block";
        }
        let analyticsBit = window.localStorage.getItem('analytics_bit');
        if(!analyticsBit || analyticsBit == 3) {
            sendPageView("/lp/unqualified");
            window.localStorage.setItem('analytics_bit', 1);
        }
    }
}

