import { downloadClick } from "./downloadClick";
import { sendPageView, linkClick } from "../../../../utils/SiteAnalytics";
import { subscribe } from "./subscribe";

export const registerUserEvents = () => {
    //functions to query api server to get whoami and register affiliates
    var scrolled = false;
    var hasError = false;    
    window.downloadClick = downloadClick;
    window.onscroll = function() {
        if(!scrolled) {
            scrolled = true;
            sendPageView("/lp/scrolled")
        }			
    };
    window.linkClick = linkClick;
    window.onbeforeunload = function(){
        sendPageView("/lp/closed")
    };
    document.getElementById('subsc_btn_id').onclick = function(event) {
        event.preventDefault();
        return subscribe();
    };
    document.getElementById('subsc_email').onkeydown = function(evt) {
        if(!hasError) {
            return;
        }
        removeClass(this, "error");
        hasError = false;
        //get the error div and if its visible and email is valid hide it
    }
}
