import { setHeroView } from "./setHeroView";

export const manageMutation = () => {
    // Configure observer mutation for installed extension
    const targetNode = document.getElementById('bl-websafety-ext-info');
    const config = { childList: true, subtree: true };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, { childList: true, subtree: true });

    // Callback function to execute when mutations are observed
    function callback(mutationsList, observer) {
        for(const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                setHeroView();
                // Stop observing
                observer.disconnect();
            }
        }
    }
}		

