import { TranslationUtil } from "../../../../utils/TranslationUtil";
import { sendPageView, linkClick } from "../../../../utils/SiteAnalytics";
import { ExtensionUtil } from "../../../../utils/ExtensionUtil";
import { DetectBrowser } from "../../../../utils/DetectBrowser";

const lang = TranslationUtil.GetSupportedLanguage();
const browserName = DetectBrowser.getBrowserName();
const showHeroAfterBtnClick = () => {
    // add install video to the hero
    if(navigator.userAgent.match(/(?=.*Edg\/[678])/gi) || navigator.userAgent.indexOf("Chrome") != -1) {
        let elem_body = document.getElementById("body");
        let elem_source = document.getElementById("blurVideoIdHeroSource");
        let blur_video_block = document.getElementById("blurVideoIdHero");
        let elem_video = document.getElementById("blurVideoIdHeroVideo");
        if (elem_body) {
            if (elem_body.classList.contains("wide_hero")) {
                elem_body.classList.remove("wide_hero");
            }
            elem_body.classList.add("video_hero");
        }
        // set video source
        if (lang && elem_source) {
            if (navigator.userAgent.match(/(?=.*Edg\/([7-9]\d|[1-9]\d{2,}))/gi)) {
                elem_source.src = '/websafety/lpmedia/videos/lp_Web_Safety_install_video_edge.mp4?id=5.0&lang=' + lang;
            } else if (navigator.userAgent.indexOf("Chrome") != -1) {
                elem_source.src = '/websafety/lpmedia/videos/lp_Web_Safety_install_video_chrome.mp4?id=5.0&lang=' + lang;
            }
            // load video
            elem_video.load();
            elem_video.play();
        }
        if (blur_video_block) {
            blur_video_block.style.display = 'block';
        }
    }
}
export const downloadClick = (section) => {
    linkClick('browse', section, true);
    let storeUrl = ExtensionUtil.GetStoreLink(browserName, lang, "browse.live", "organic", "lp", "WebSafety");
    let storeTarget = ExtensionUtil.GetStoreLinkOpenTarget(browserName);
    // download extension or open extension store page
    window.open(storeUrl, storeTarget);
    showHeroAfterBtnClick();
}
