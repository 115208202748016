export const setHeroView = () => {
    let extension_elem = document.getElementById("bl-websafety-ext-installed");
    if (extension_elem && extension_elem.innerHTML && extension_elem.innerHTML !== "") {
        let elem_body = document.getElementById("body");
        let hero_afterInstall = document.getElementById("afterInstallHeroContent");
        let hero_beforeInstall = document.getElementById("beforeInstallHeroContent");
        let hero_video = document.getElementById("blurVideoIdHero");
        let nav_installBtn = document.getElementById("lpGetItHereBtn");
        let footer_downloadBtn = document.getElementById("footerDownloadId");
                
        if (elem_body ) {
            if (elem_body.classList.contains("video_hero")) {
                elem_body.classList.remove("video_hero");
            }
            elem_body.classList.add("wide_hero");
        }
        if (hero_beforeInstall) {
            hero_beforeInstall.style.display = 'none';
        }
        if (hero_video) {
            hero_video.style.display = 'none';
        }
        if (nav_installBtn) {
            nav_installBtn.style.display = 'none';
        }
        if (hero_afterInstall) {
            hero_afterInstall.style.display = 'block';
        }
        if (footer_downloadBtn) {
            footer_downloadBtn.style.display = 'none';
        }
    } else {
        if (document.getElementById("beforeInstallHeroContent")) {
            document.getElementById("beforeInstallHeroContent").style.display = 'block';
        }
    }
}
