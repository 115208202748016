import { sendPageView } from "../../../../utils/SiteAnalytics";

export const subscribe = () => {
    var email_input = document.getElementById("subsc_email");
    if(!email_input) {
        return;
    }
    var email = email_input.value;
    if(!isEmailValid(email)) {
        hasError = true;
        addNewClass(email_input, "error");
        //show error
    } else {
        var contactUrl = "https://anonymous.browse.live/blur/contact";
        var contactJson = {
            email: email,
            contactType: "needBrowserSupport",
            captcha: captchaToken,
        };
        var data = JSON.stringify(contactJson);
        
        sendPageView("/lp/subscribe")
        var xhttp = new XMLHttpRequest();
        xhttp.withCredentials = true;
        xhttp.open("POST", contactUrl);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.send(data);
        //we will not wait for response
        //if error div is visible hide it
        //display success message div
        var subscFormId = document.getElementById("subscFormId");
        if(subscFormId) {
            subscFormId.style.display = "none";
        }
        var successId = document.getElementById("subscformSuccess");
        if(successId) {
            successId.style.display = "block";
        }
    }
    return true;
}
