import "./libraries/flexibility";
import { TranslationUtil } from "../../../utils/TranslationUtil";
import { scrollEvent } from "./methods/scrollEvent";
import { setHeroView } from "./methods/setHeroView";
import { manageMutation } from "./methods/manageMutation";
import { processSupport } from "./methods/processSupport";
import { sendPageView, UTMCheck } from "../../../utils/SiteAnalytics";
import { registerUserEvents } from "./methods/registerUserEvents";
import { DetectBrowser } from "../../../utils/DetectBrowser";
import { RtlUtil } from "../../../utils/RtlUtil";

import "../sass/style.scss";
import { Analytics } from "../../../common/Analytics";

let lang = TranslationUtil.GetSupportedLanguage();
let isRtl = RtlUtil.GetSetRtl(lang);

function pingExtension(sendEvents=true) {
	var xhttp = new XMLHttpRequest();
	xhttp.withCredentials = true;
	if(sendEvents === true) {
		xhttp.addEventListener("load", postPingExtension);
		xhttp.addEventListener("error", postPingExtension);
		xhttp.addEventListener("abort", postPingExtension);
	}
	xhttp.open("GET", "https://api.blur.live/license/pingextension", true);
	xhttp.send();
}

function registerAffiliates() {
	//do only when it has affiliate id associated
	if(window.location.href && window.location.href.indexOf("affiliate=") > -1) {
		var xhttp = new XMLHttpRequest();
		xhttp.withCredentials = true;
		xhttp.addEventListener("load", postAffRegister);
		xhttp.addEventListener("error", postAffRegister);
		xhttp.addEventListener("abort", postAffRegister);
		var query_string_paramter = window.location.href.split("?")[1];   
		var affUrl = "https://api.blur.live/license/registeraffiliate?" + query_string_paramter;
		xhttp.open("GET", affUrl, true);
		xhttp.send();
	} else {
		callWhoAmI();
	}
}
function postAffRegister() {
	pingExtension(false); //this to communicate to extension
	callWhoAmI();
}
function callWhoAmI() {
	var xhttp = new XMLHttpRequest();
	let whoamiUrl = "https://api.blur.live/license/whoami";
	if (lang && !lang.includes('en')) {
		whoamiUrl = whoamiUrl + "?lang=" + lang;
	}
	xhttp.withCredentials = true;
	xhttp.onreadystatechange = function() {
		if (this.readyState == 4 && this.status == 200) {
			var response = JSON.parse(this.responseText);
			if(response && response.affiliateObject && response.affiliateObject.logo && document.getElementById('affiliateBlockImg')) {
				document.getElementById('affiliateBlockImg').src = response.affiliateObject.logo;
				if (document.getElementById('affiliateBlock')) {
					document.getElementById('affiliateBlock').style.display = 'block';
				}
				if (document.getElementById('affiliateBlockLp') && response.affiliateObject.infoPage) {
					document.getElementById('affiliateBlockLp').href = response.affiliateObject.infoPage;
				}
				if (document.getElementById('affiliateBlockLp') && response.affiliateObject.affiliateString) {
					document.getElementById('affiliateBlockLp').innerHTML = response.affiliateObject.affiliateString;
				}
				//set the pricing page
				let affObj = response.affiliateObject;
				let buyPremiumUrl = affObj.buyUrl ? affObj.buyUrl : "https://browse.live/websafety/premium";
				var pricingUrl = affObj.pricingUrl ? affObj.pricingUrl : "https://browse.live/websafety/pricing";
				if(document.getElementById("viewPricingLink")) {
					document.getElementById("viewPricingLink").href = pricingUrl;
				}
				if(document.getElementById("viewFooterPricingLink")) {
					document.getElementById("viewFooterPricingLink").href = pricingUrl;
				}								
			}
		}
	};
	xhttp.open("GET", whoamiUrl, true);
	xhttp.send();
}

function postPingExtension() {
	registerAffiliates(); //register affiliates
}

let browserName = DetectBrowser.getBrowserName();
let isSupported = DetectBrowser.isBrowserSupported(browserName);

if(isRtl) {
	document.body.classList.add("page-rtl");
}


UTMCheck();
let analyticsBit = window.localStorage.getItem('analytics_bit');
if(!analyticsBit || analyticsBit == 3) {
	sendPageView("/websafety");
}
//set UTM Data here
TranslationUtil.GetDefinition(lang, "lp").then(resp => {
	//here we have all the translations, let read and translate
	//send site analytics
	let translations = resp["lp"] ? resp["lp"] : {};
	registerUserEvents();
	processSupport(isSupported, browserName, translations);
	manageMutation();
	setHeroView();
	scrollEvent();
});

document.addEventListener('DOMContentLoaded',function() {
	pingExtension();
});
